/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
            url
            site_name
            twitterSite
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title; //optional chaining
  const defaultImage = site.siteMetadata?.image;
  const defaultTwitterSite = site.siteMetadata?.twitterSite;
  const site_name = site.siteMetadata?.site_name;
  const url = site.siteMetadata?.url;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[{ rel: `canonical`, href: url }]}
      title={defaultTitle}
      titleTemplate={defaultTitle ? defaultTitle : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: defaultTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:site_name`,
          content: site_name,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:image`,
          content: defaultImage,
        },
        {
          property: `og:image:secure_url`,
          content: defaultImage,
        },
        {
          property: `og:image:width`,
          content: "1280",
        },
        {
          property: `og:image:height`,
          content: "640",
        },
        {
          name: `twitter:title`,
          content: defaultTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:card`,
          content: "website",
        },
        {
          name: `twitter:card`,
          content: "summary_large_image",
        },
        {
          name: `twitter:image`,
          content: defaultImage,
        },
        {
          name: `twitter:site`,
          content: defaultTwitterSite,
        },
      ].concat(meta)}
    >
      {/* {process.env.NODE_ENV === "production" && (
        <script
          type="text/javascript"
          src="https://my.hellobar.com/fb787b2d3d96351dec1d73fba73bcf21b6378c56.js"
          async="async"
        ></script>
      )} */}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
