import React from "react";
import Header from "../components/CoreComponents/Header";
import {
	black,
	mainCTAs,
	primary,
	secondary,
} from "../components/CTAs/CTAs.module.scss";
import Footer from "../components/Footer";
import SEO from "../components/seo";
import styles from "./404.module.scss";

export default (props) => {
	return (
		<>
			<SEO title="SpringVerify" />
			<div className="topSection">
				<Header />
				<div className={styles.container}>
					<div className={styles.card}>
						<img src={require("../images/404.svg")} />
						<h1>The page you are trying to reach does not exist</h1>
						<div className="show-sm">
							<p>Go back to</p>
							<div className={mainCTAs}>
								<a
									href={process.env.SV_IN_LANDING}
									className={primary}
								>
									Home page
								</a>
								<a
									className={`${secondary} ${black}`}
									onClick={() => {
										window.Tawk_API.maximize();
									}}
								>
									Contact us
								</a>
							</div>
						</div>
						<p className="show-lg">
							Go back to{" "}
							<a href={process.env.SV_IN_LANDING}>
								in.springverify.com
							</a>{" "}
							or{" "}
							<a
								onClick={() => {
									window.Tawk_API.maximize();
								}}
							>
								contact us
							</a>{" "}
							about the problem
						</p>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};
