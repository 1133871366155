import React, { useState } from "react";
import styles from "./core.module.scss";
import Hamburger from "../../images/hamburger.svg";
import HamburgerBlue from "../../images/hamburger-blue.svg";
import Close from "../../images/close.svg";
import Down from "../../images/down.svg";

const Header = () => {
  const [mobNav, setMobNav] = useState(false);
  const path =
    typeof window !== `undefined`
      ? window.location.pathname.replace(/\/$/, "")
      : null;

  return (
    <>
      <header className={styles.header}>
        <a href="/">
          {path === "/thank-you" ? (
            <img
              data-src={"https://assets-sv-in.gumlet.io/logo/logo-blue.svg"}
              alt="SpringVerify Logo"
            />
          ) : (
            <img
              data-src={"https://assets-sv-in.gumlet.io/logo/logo.svg"}
              alt="SpringVerify Logo"
            />
          )}
        </a>
        <div className={styles.right}>
          {path !== "/background-verification" && (
            <div className={styles.links}>
              <span>
                <img
                  data-src={
                    "https://assets-sv-in.gumlet.io/logo/springverify-india.png"
                  }
                  className={styles.location}
                  alt={"IND"}
                />
                <span>SpringVerify India</span>
                <img src={Down} alt={"down"} />
                <div className={styles.dropdown}>
                  <a href="/">
                    <img
                      data-src={
                        "https://assets-sv-in.gumlet.io/logo/springverify-india.png"
                      }
                      className={styles.location}
                      alt={"IND"}
                    />
                    SpringVerify India
                  </a>
                  <a href={process.env.GATSBY_SV_US_URL}>
                    <img
                      data-src={
                        "https://assets-sv-in.gumlet.io/logo/springverify-usa.png"
                      }
                      className={styles.location}
                      alt={"USA"}
                    />
                    SpringVerify USA
                  </a>
                </div>
              </span>
              <span>
                <a
                  href="https://whatsapp.springverify.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Verify on Whatsapp
                </a>
              </span>
            </div>
          )}
          <a
            href={process.env.GATSBY_SV_IN_URL}
            target="_blank"
            rel="noreferrer"
            className={styles.getStarted}
          >
            Sign In
          </a>
        </div>
        {path === "/thank-you" ? (
          <img
            src={HamburgerBlue}
            className={styles.hamburger}
            onClick={() => {
              setMobNav(true);
            }}
            alt={"menu"}
          />
        ) : (
          ["", "404"].includes("path") && (
            <img
              src={Hamburger}
              className={styles.hamburger}
              onClick={() => {
                setMobNav(true);
              }}
              alt={"menu"}
            />
          )
        )}
      </header>
      {mobNav && (
        <nav className={styles.mobNav}>
          <div className={styles.header}>
            <img
              src={Close}
              onClick={() => {
                setMobNav(false);
              }}
              alt={"close"}
            />
          </div>
          <div className={styles.list}>
            <a href="/">
              <img
                data-src={
                  "https://assets-sv-in.gumlet.io/logo/springverify-india.png"
                }
                className={styles.location}
                alt={"IND"}
              />
              SpringVerify India
            </a>
            <a href={process.env.GATSBY_SV_US_URL}>
              <img
                data-src={
                  "https://assets-sv-in.gumlet.io/logo/springverify-usa.png"
                }
                className={styles.location}
                alt={"USA"}
              />
              SpringVerify USA
            </a>
            <a
              href="https://whatsapp.springverify.com/"
              target="_blank"
              rel="noreferrer"
            >
              Verify on Whatsapp
            </a>
          </div>
        </nav>
      )}
    </>
  );
};

export default Header;
