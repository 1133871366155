import React from "react";

import "./styles.scss";

export default () => {
  return (
    <div className="svFooter" id="get-started">
      <div className="svContainer">
        <div className="svFooterContact">
          <h5>Call us now: +91 90 1990 6005</h5>
          <h5>
            SpringRole India Pvt. Ltd.
            <br />
            No.19, 2nd Floor, Amar Plaza,
            <br />
            Krishnanagar Industrial Layout Hosur Road,
            <br />
            Bengaluru, Karnataka, 560029
          </h5>
        </div>

        <div className={"digilockerContainer"}>
          <img
            data-src={"https://assets-sv-in.gumlet.io/digiLocker.png"}
            alt="digilocker-img"
          />
        </div>

        <div className="footerTermService">
          <a
            href="https://springverify-assets-id.s3.amazonaws.com/Springworks-Terms-of-Service.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>
          |
          <a
            href="https://springrecruit-assets.s3.amazonaws.com/PrivacyPolicy-revised-1-aug-20.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy{" "}
          </a>
        </div>
      </div>
    </div>
  );
};
